import { MessageService } from '@progress/kendo-angular-l10n';

const data = {
  es: {
    rtl: false,
    messages: {
      'kendo.calendar.today': 'Hoy',
      'kendo.datepicker.today': 'Hoy',
      'kendo.datepicker.toggle': 'Abrir calendario',
      'kendo.grid.groupPanelEmpty': 'Arrastre el título de una columna y suéltelo aquí para agrupar por ese criterio',
      'kendo.grid.noRecords': 'No hay datos disponibles.',
      'kendo.grid.pagerFirstPage': 'Ir a la primera página',
      'kendo.grid.pagerPreviousPage': 'Ir a la página anterior',
      'kendo.grid.pagerNextPage': 'Ir a la página siguiente',
      'kendo.grid.pagerLastPage': 'Ir a la última página',
      'kendo.grid.pagerPage': 'Página',
      'kendo.grid.pagerOf': 'de',
      'kendo.grid.pagerItems': 'ítems',
      'kendo.grid.pagerItemsPerPage': 'ítems por página',
      'kendo.grid.pagerPageNumberInputTitle': 'Número de Página',
      'kendo.grid.filter': 'Filtrar',
      'kendo.grid.filterEqOperator': 'Es igual a',
      'kendo.grid.filterNotEqOperator': 'No es igual a',
      'kendo.grid.filterIsNullOperator': 'Es nulo',
      'kendo.grid.filterIsNotNullOperator': 'No es nulo',
      'kendo.grid.filterIsEmptyOperator': 'Está vacío',
      'kendo.grid.filterIsNotEmptyOperator': 'No está vacío',
      'kendo.grid.filterStartsWithOperator': 'Comienza con',
      'kendo.grid.filterContainsOperator': 'Contiene',
      'kendo.grid.filterNotContainsOperator': 'No contiene',
      'kendo.grid.filterEndsWithOperator': 'Termina en',
      'kendo.grid.filterGteOperator': 'Es mayor o igual que',
      'kendo.grid.filterGtOperator': 'Es mayor que',
      'kendo.grid.filterLteOperator': 'Es menor o igual que',
      'kendo.grid.filterLtOperator': 'Es menor o igual que',
      'kendo.grid.filterIsTrue': 'Sí',
      'kendo.grid.filterIsFalse': 'No',
      'kendo.grid.filterBooleanAll': '(Todas)',
      'kendo.grid.filterAfterOrEqualOperator': 'Es posterior o igual a',
      'kendo.grid.filterAfterOperator': 'Es posterior',
      'kendo.grid.filterBeforeOperator': 'Es anterior',
      'kendo.grid.filterBeforeOrEqualOperator': 'Es anterior o igual a',
      'kendo.grid.filterFilterButton': 'Filtrar',
      'kendo.grid.filterClearButton': 'Limpiar filtros',
      'kendo.grid.filterAndLogic': 'Y',
      'kendo.grid.filterOrLogic': 'O',
      'kendo.grid.filterDateToggle': 'Mostrar calendario',
      'kendo.grid.filterDateToday': 'Hoy',
      'kendo.grid.filterNumericDecrement': 'Disminuir valor',
      'kendo.grid.filterNumericIncrement': 'Incrementar valor',
      'kendo.grid.loading': 'Cargando',
      'kendo.grid.sort': 'Ordenar',
      'kendo.grid.columnMenu': 'Menú Columna',
      'kendo.grid.columns': 'Columnas',
      'kendo.grid.lock': 'Bloquear',
      'kendo.grid.unlock': 'Desbloquear',
      'kendo.grid.sortAscending': 'Orden ascendente',
      'kendo.grid.sortDescending': 'Orden descendente',
      'kendo.grid.columnsApply': 'Aplicar',
      'kendo.grid.columnsReset': 'Defecto',
      'kendo.grid.sortable': 'Ordenable',
      'kendo.grid.sortedAscending': 'Ordenado ascendentemente',
      'kendo.grid.sortedDescending': 'Ordenado descendentemente',
      'kendo.grid.sortedDefault': 'No ordenado',
    },
  },
  en: {
    rtl: false,
    messages: {
      'kendo.calendar.today': 'Today',
      'kendo.datepicker.today': 'Today',
      'kendo.datepicker.toggle': 'Toggle calendar',
      'kendo.grid.groupPanelEmpty': 'Drag a column header and drop it here to group by that column',
      'kendo.grid.noRecords': 'No records available.',
      'kendo.grid.pagerFirstPage': 'Go to the first page',
      'kendo.grid.pagerPreviousPage': 'Go to the previous page',
      'kendo.grid.pagerNextPage': 'Go to the next page',
      'kendo.grid.pagerLastPage': 'Go to the last page',
      'kendo.grid.pagerPage': 'Page',
      'kendo.grid.pagerOf': 'of',
      'kendo.grid.pagerItems': 'items',
      'kendo.grid.pagerItemsPerPage': 'items per page',
      'kendo.grid.pagerPageNumberInputTitle': 'Page Number',
      'kendo.grid.filter': 'Filter',
      'kendo.grid.filterEqOperator': 'Is equal to',
      'kendo.grid.filterNotEqOperator': 'Is not equal to',
      'kendo.grid.filterIsNullOperator': 'Is null',
      'kendo.grid.filterIsNotNullOperator': 'Is not null',
      'kendo.grid.filterIsEmptyOperator': 'Is empty',
      'kendo.grid.filterIsNotEmptyOperator': 'Is not empty',
      'kendo.grid.filterStartsWithOperator': 'Starts with',
      'kendo.grid.filterContainsOperator': 'Contains',
      'kendo.grid.filterNotContainsOperator': 'Does not contain',
      'kendo.grid.filterEndsWithOperator': 'Ends with',
      'kendo.grid.filterGteOperator': 'Is greater than or equal to',
      'kendo.grid.filterGtOperator': 'Is greater than',
      'kendo.grid.filterLteOperator': 'Is less than or equal to',
      'kendo.grid.filterLtOperator': 'Is less than',
      'kendo.grid.filterIsTrue': 'is true',
      'kendo.grid.filterIsFalse': 'is false',
      'kendo.grid.filterBooleanAll': '(All)',
      'kendo.grid.filterAfterOrEqualOperator': 'Is after or equal to',
      'kendo.grid.filterAfterOperator': 'Is after',
      'kendo.grid.filterBeforeOperator': 'Is before',
      'kendo.grid.filterBeforeOrEqualOperator': 'Is before or equal to',
      'kendo.grid.filterFilterButton': 'Filter',
      'kendo.grid.filterClearButton': 'Clear',
      'kendo.grid.filterAndLogic': 'And',
      'kendo.grid.filterOrLogic': 'Or',
      'kendo.grid.filterDateToggle': 'Toggle calendar',
      'kendo.grid.filterDateToday': 'Today',
      'kendo.grid.filterNumericDecrement': 'Decrease value',
      'kendo.grid.filterNumericIncrement': 'Increase value',
      'kendo.grid.loading': 'Loading',
      'kendo.grid.sort': 'Sort',
      'kendo.grid.columnMenu': 'Column Menu',
      'kendo.grid.columns': 'Columns',
      'kendo.grid.lock': 'Lock',
      'kendo.grid.unlock': 'Unlock',
      'kendo.grid.sortAscending': 'Sort Ascending',
      'kendo.grid.sortDescending': 'Sort Descending',
      'kendo.grid.columnsApply': 'Apply',
      'kendo.grid.columnsReset': 'Reset',
      'kendo.grid.sortable': 'Sortable',
      'kendo.grid.sortedAscending': 'Sorted ascending',
      'kendo.grid.sortedDescending': 'Sorted descending',
      'kendo.grid.sortedDefault': 'Not sorted',
    },
  },
  it: {
    rtl: false,
    messages: {
      'kendo.calendar.today': 'Oggi',
      'kendo.datepicker.today': 'Oggi',
      'kendo.datepicker.toggle': 'Toggle calendar',
      'kendo.grid.groupPanelEmpty': "Trascina l'header di una colonna e rilascialo qui per raggruppare secondo tale colonna",
      'kendo.grid.noRecords': 'Nessun record disponibile.',
      'kendo.grid.pagerFirstPage': 'Vai alla prima pagina',
      'kendo.grid.pagerPreviousPage': 'Vai alla pagina precedente',
      'kendo.grid.pagerNextPage': 'Vai alla prossima pagina',
      'kendo.grid.pagerLastPage': "Vai all'ultima pagina",
      'kendo.grid.pagerPage': 'Pagina',
      'kendo.grid.pagerOf': 'di',
      'kendo.grid.pagerItems': 'elementi',
      'kendo.grid.pagerItemsPerPage': 'elementi per pagina',
      'kendo.grid.pagerPageNumberInputTitle': 'Page Number',
      'kendo.grid.filter': 'Filter',
      'kendo.grid.filterEqOperator': 'È uguale a',
      'kendo.grid.filterNotEqOperator': 'Non è uguale a',
      'kendo.grid.filterIsNullOperator': 'è zero',
      'kendo.grid.filterIsNotNullOperator': 'Non è nullo',
      'kendo.grid.filterIsEmptyOperator': 'è vuoto',
      'kendo.grid.filterIsNotEmptyOperator': 'non è vuoto',
      'kendo.grid.filterStartsWithOperator': 'Inizia con',
      'kendo.grid.filterContainsOperator': 'Contiene',
      'kendo.grid.filterNotContainsOperator': 'Non contiene',
      'kendo.grid.filterEndsWithOperator': 'Finisce con',
      'kendo.grid.filterGteOperator': 'È più grande o uguale a',
      'kendo.grid.filterGtOperator': 'È più grande di',
      'kendo.grid.filterLteOperator': 'È più piccolo o uguale a',
      'kendo.grid.filterLtOperator': 'È più piccolo o uguale a',
      'kendo.grid.filterIsTrue': 'è vero',
      'kendo.grid.filterIsFalse': 'è falso',
      'kendo.grid.filterBooleanAll': '(Tutti)',
      'kendo.grid.filterAfterOrEqualOperator': 'È dopo o uguale a',
      'kendo.grid.filterAfterOperator': 'È dopo',
      'kendo.grid.filterBeforeOperator': 'È prima',
      'kendo.grid.filterBeforeOrEqualOperator': 'È prima o uguale a',
      'kendo.grid.filterFilterButton': 'Filtro',
      'kendo.grid.filterClearButton': 'Rimuovi',
      'kendo.grid.filterAndLogic': 'E',
      'kendo.grid.filterOrLogic': 'O',
      'kendo.grid.filterDateToggle': 'Mostra calendario',
      'kendo.grid.filterDateToday': 'Oggi',
      'kendo.grid.filterNumericDecrement': 'Decrementa',
      'kendo.grid.filterNumericIncrement': 'Aumenta',
      'kendo.grid.loading': 'Caricamento',
      'kendo.grid.sort': 'Ordina',
      'kendo.grid.columnMenu': 'Menu Colonna',
      'kendo.grid.columns': 'Colonne',
      'kendo.grid.lock': 'Blocca',
      'kendo.grid.unlock': 'Sblocca',
      'kendo.grid.sortAscending': 'Ordina Ascendente',
      'kendo.grid.sortDescending': 'Ordina Discendente',
      'kendo.grid.columnsApply': 'Applica',
      'kendo.grid.columnsReset': 'Reset',
      'kendo.grid.sortable': 'Ordinabile',
      'kendo.grid.sortedAscending': 'Ordinato ascendente',
      'kendo.grid.sortedDescending': 'Ordinato discendente',
      'kendo.grid.sortedDefault': 'Non ordinato',
    },
    pt: {
      rtl: false,
      messages: {
        'kendo.calendar.today': 'Hoje',
        'kendo.datepicker.today': 'Hoje',
        'kendo.datepicker.toggle': 'Toggle calendar',
        'kendo.grid.groupPanelEmpty': 'Arraste uma coluna para este espaço para agrupar pelo valor da mesma',
        'kendo.grid.noRecords': 'Nenhum registo disponível.',
        'kendo.grid.pagerFirstPage': 'Ir para a primeira página',
        'kendo.grid.pagerPreviousPage': 'Ir para a página anterior',
        'kendo.grid.pagerNextPage': 'Ir para a próxima página',
        'kendo.grid.pagerLastPage': 'Ir para a última página',
        'kendo.grid.pagerPage': 'Página',
        'kendo.grid.pagerOf': 'de',
        'kendo.grid.pagerItems': 'itens',
        'kendo.grid.pagerItemsPerPage': 'itens por página',
        'kendo.grid.pagerPageNumberInputTitle': 'Page Number',
        'kendo.grid.filter': 'Filter',
        'kendo.grid.filterEqOperator': 'É igual a',
        'kendo.grid.filterNotEqOperator': 'Não é igual a',
        'kendo.grid.filterIsNullOperator': 'É nulo',
        'kendo.grid.filterIsNotNullOperator': 'É não nulo',
        'kendo.grid.filterIsEmptyOperator': 'É vazio',
        'kendo.grid.filterIsNotEmptyOperator': 'É não vazio',
        'kendo.grid.filterStartsWithOperator': 'Começa com',
        'kendo.grid.filterContainsOperator': 'Contém',
        'kendo.grid.filterNotContainsOperator': 'Não contém',
        'kendo.grid.filterEndsWithOperator': 'Termina com',
        'kendo.grid.filterGteOperator': 'É maior ou igual a',
        'kendo.grid.filterGtOperator': 'É maior que',
        'kendo.grid.filterLteOperator': 'É menor ou igual a',
        'kendo.grid.filterLtOperator': 'É menor ou igual a',
        'kendo.grid.filterIsTrue': 'é verdadeiro',
        'kendo.grid.filterIsFalse': 'é falso',
        'kendo.grid.filterBooleanAll': '(Todos)',
        'kendo.grid.filterAfterOrEqualOperator': 'É posterior ou igual a',
        'kendo.grid.filterAfterOperator': 'É posterior a',
        'kendo.grid.filterBeforeOperator': 'É anterior a',
        'kendo.grid.filterBeforeOrEqualOperator': 'É anterior ou igual a',
        'kendo.grid.filterFilterButton': 'Filtrar',
        'kendo.grid.filterClearButton': 'Limpar',
        'kendo.grid.filterAndLogic': 'E',
        'kendo.grid.filterOrLogic': 'OU',
        'kendo.grid.filterDateToggle': 'Toggle calendar',
        'kendo.grid.filterDateToday': 'Hoje',
        'kendo.grid.filterNumericDecrement': 'Diminuir valor',
        'kendo.grid.filterNumericIncrement': 'Aumentar valor',
        'kendo.grid.loading': 'Loading',
        'kendo.grid.sort': 'Sort',
        'kendo.grid.columnMenu': 'Column Menu',
        'kendo.grid.columns': 'Columns',
        'kendo.grid.lock': 'Lock',
        'kendo.grid.unlock': 'Unlock',
        'kendo.grid.sortAscending': 'Sort Ascending',
        'kendo.grid.sortDescending': 'Sort Descending',
        'kendo.grid.columnsApply': 'Apply',
        'kendo.grid.columnsReset': 'Reset',
        'kendo.grid.sortable': 'Sortable',
        'kendo.grid.sortedAscending': 'Sorted ascending',
        'kendo.grid.sortedDescending': 'Sorted descending',
        'kendo.grid.sortedDefault': 'Not sorted',
      },
    },
  },
  pt: {
    rtl: false,
    messages: {
      'kendo.calendar.today': 'Hoje',
      'kendo.datepicker.today': 'Hoje',
      'kendo.datepicker.toggle': 'Toggle calendar',
      'kendo.grid.groupPanelEmpty': 'Arraste uma coluna para este espaço para agrupar pelo valor da mesma',
      'kendo.grid.noRecords': 'Nenhum registo disponível.',
      'kendo.grid.pagerFirstPage': 'Ir para a primeira página',
      'kendo.grid.pagerPreviousPage': 'Ir para a página anterior',
      'kendo.grid.pagerNextPage': 'Ir para a próxima página',
      'kendo.grid.pagerLastPage': 'Ir para a última página',
      'kendo.grid.pagerPage': 'Página',
      'kendo.grid.pagerOf': 'de',
      'kendo.grid.pagerItems': 'itens',
      'kendo.grid.pagerItemsPerPage': 'itens por página',
      'kendo.grid.pagerPageNumberInputTitle': 'Page Number',
      'kendo.grid.filter': 'Filter',
      'kendo.grid.filterEqOperator': 'É igual a',
      'kendo.grid.filterNotEqOperator': 'Não é igual a',
      'kendo.grid.filterIsNullOperator': 'É nulo',
      'kendo.grid.filterIsNotNullOperator': 'É não nulo',
      'kendo.grid.filterIsEmptyOperator': 'É não vazio',
      'kendo.grid.filterIsNotEmptyOperator': 'É não vazio',
      'kendo.grid.filterStartsWithOperator': 'Começa com',
      'kendo.grid.filterContainsOperator': 'Contém',
      'kendo.grid.filterNotContainsOperator': 'Não contém',
      'kendo.grid.filterEndsWithOperator': 'Termina com',
      'kendo.grid.filterGteOperator': 'É maior ou igual a',
      'kendo.grid.filterGtOperator': 'É maior que',
      'kendo.grid.filterLteOperator': 'É menor ou igual a',
      'kendo.grid.filterLtOperator': 'É menor ou igual a',
      'kendo.grid.filterIsTrue': 'é verdadeiro',
      'kendo.grid.filterIsFalse': 'é falso',
      'kendo.grid.filterBooleanAll': '(Todos)',
      'kendo.grid.filterAfterOrEqualOperator': 'É posterior ou igual a',
      'kendo.grid.filterAfterOperator': 'É posterior a',
      'kendo.grid.filterBeforeOperator': 'É anterior a',
      'kendo.grid.filterBeforeOrEqualOperator': 'É anterior ou igual a',
      'kendo.grid.filterFilterButton': 'Filtrar',
      'kendo.grid.filterClearButton': 'Limpar',
      'kendo.grid.filterAndLogic': 'E',
      'kendo.grid.filterOrLogic': 'OU',
      'kendo.grid.filterDateToggle': 'Toggle calendar',
      'kendo.grid.filterDateToday': 'Hoje',
      'kendo.grid.filterNumericDecrement': 'Diminuir valor',
      'kendo.grid.filterNumericIncrement': 'Aumentar valor',
      'kendo.grid.loading': 'Loading',
      'kendo.grid.sort': 'Sort',
      'kendo.grid.columnMenu': 'Column Menu',
      'kendo.grid.columns': 'Columns',
      'kendo.grid.lock': 'Lock',
      'kendo.grid.unlock': 'Unlock',
      'kendo.grid.sortAscending': 'Sort Ascending',
      'kendo.grid.sortDescending': 'Sort Descending',
      'kendo.grid.columnsApply': 'Apply',
      'kendo.grid.columnsReset': 'Reset',
      'kendo.grid.sortable': 'Sortable',
      'kendo.grid.sortedAscending': 'Sorted ascending',
      'kendo.grid.sortedDescending': 'Sorted descending',
      'kendo.grid.sortedDefault': 'Not sorted',
    },
  },
  fr: {
    rtl: false,
    messages: {
      'kendo.calendar.today': 'Aujourd\'hui',
      'kendo.datepicker.today': 'Aujourd\'hui',
      'kendo.datepicker.toggle': 'Toggle calendar',
      'kendo.grid.groupPanelEmpty': 'Faites glisser un en-tête de colonne et déposer ici pour grouper par cette colonne.',
      'kendo.grid.noRecords': 'No records available.',
      'kendo.grid.pagerFirstPage': 'Aller à la première page',
      'kendo.grid.pagerPreviousPage': 'Aller à la page précédente',
      'kendo.grid.pagerNextPage': 'Aller à la page suivante',
      'kendo.grid.pagerLastPage': 'Aller à la dernière page',
      'kendo.grid.pagerPage': 'Page',
      'kendo.grid.pagerOf': 'de',
      'kendo.grid.pagerItems': 'éléments',
      'kendo.grid.pagerItemsPerPage': 'éléments par page',
      'kendo.grid.pagerPageNumberInputTitle': 'Page Number',
      'kendo.grid.filter': 'Filtrer',
      'kendo.grid.filterEqOperator': 'Est égal à',
      'kendo.grid.filterNotEqOperator': 'N’est pas égal à',
      'kendo.grid.filterIsNullOperator': 'Est nulle',
      'kendo.grid.filterIsNotNullOperator': 'N’est pas nulle',
      'kendo.grid.filterIsEmptyOperator': 'Est vide',
      'kendo.grid.filterIsNotEmptyOperator': 'N’est pas vide',
      'kendo.grid.filterStartsWithOperator': 'Commence par',
      'kendo.grid.filterContainsOperator': 'Contient',
      'kendo.grid.filterNotContainsOperator': 'Ne contient pas',
      'kendo.grid.filterEndsWithOperator': 'Se termine par',
      'kendo.grid.filterGteOperator': 'Est supérieur ou égal à',
      'kendo.grid.filterGtOperator': 'Est supérieur à',
      'kendo.grid.filterLteOperator': 'Est inférieur ou égal à',
      'kendo.grid.filterLtOperator': 'Est inférieur',
      'kendo.grid.filterIsTrue': 'est vrai',
      'kendo.grid.filterIsFalse': 'est fausse',
      'kendo.grid.filterBooleanAll': '(Tout)',
      'kendo.grid.filterAfterOrEqualOperator': 'Est postérieur ou égal à',
      'kendo.grid.filterAfterOperator': 'Est postérieur',
      'kendo.grid.filterBeforeOperator': 'Est antérieur',
      'kendo.grid.filterBeforeOrEqualOperator': 'Est antérieur ou égal à',
      'kendo.grid.filterFilterButton': 'Filtrer',
      'kendo.grid.filterClearButton': 'Effacer filtre',
      'kendo.grid.filterAndLogic': 'Et',
      'kendo.grid.filterOrLogic': 'Ou',
      'kendo.grid.filterDateToggle': 'Afficher le calendrier',
      'kendo.grid.filterDateToday': 'Aujourd\'hui',
      'kendo.grid.filterNumericDecrement': 'Diminuer la valeur',
      'kendo.grid.filterNumericIncrement': 'Augmenter la valeur',
      'kendo.grid.loading': 'Chargement',
      'kendo.grid.sort': 'Tri',
      'kendo.grid.columnMenu': 'Colonnes Menu',
      'kendo.grid.columns': 'Colonnes',
      'kendo.grid.lock': 'Bloquer',
      'kendo.grid.unlock': 'Débloquer',
      'kendo.grid.sortAscending': 'Tri croissant',
      'kendo.grid.sortDescending': 'Tri décroissant',
      'kendo.grid.columnsApply': 'Appliquer',
      'kendo.grid.columnsReset': 'Réinitialiser',
      'kendo.grid.sortable': 'Sortable',
      'kendo.grid.sortedAscending': 'Sorted ascending',
      'kendo.grid.sortedDescending': 'Sorted descending',
      'kendo.grid.sortedDefault': 'Not sorted',
    },
  },
  ru: {
    rtl: false,
    messages: {
      'kendo.calendar.today': 'Today',
      'kendo.datepicker.today': 'Today',
      'kendo.datepicker.toggle': 'Toggle calendar',
      'kendo.grid.groupPanelEmpty': 'Drag a column header and drop it here to group by that column',
      'kendo.grid.noRecords': 'No records available.',
      'kendo.grid.pagerFirstPage': 'Go to the first page',
      'kendo.grid.pagerPreviousPage': 'Go to the previous page',
      'kendo.grid.pagerNextPage': 'Go to the next page',
      'kendo.grid.pagerLastPage': 'Go to the last page',
      'kendo.grid.pagerPage': 'Page',
      'kendo.grid.pagerOf': 'of',
      'kendo.grid.pagerItems': 'items',
      'kendo.grid.pagerItemsPerPage': 'items per page',
      'kendo.grid.pagerPageNumberInputTitle': 'Page Number',
      'kendo.grid.filter': 'Filter',
      'kendo.grid.filterEqOperator': 'Is equal to',
      'kendo.grid.filterNotEqOperator': 'Is not equal to',
      'kendo.grid.filterIsNullOperator': 'Is null',
      'kendo.grid.filterIsNotNullOperator': 'Is not null',
      'kendo.grid.filterIsEmptyOperator': 'Is empty',
      'kendo.grid.filterIsNotEmptyOperator': 'Is not empty',
      'kendo.grid.filterStartsWithOperator': 'Starts with',
      'kendo.grid.filterContainsOperator': 'Contains',
      'kendo.grid.filterNotContainsOperator': 'Does not contain',
      'kendo.grid.filterEndsWithOperator': 'Ends with',
      'kendo.grid.filterGteOperator': 'Is greater than or equal to',
      'kendo.grid.filterGtOperator': 'Is greater than',
      'kendo.grid.filterLteOperator': 'Is less than or equal to',
      'kendo.grid.filterLtOperator': 'Is less than',
      'kendo.grid.filterIsTrue': 'is true',
      'kendo.grid.filterIsFalse': 'is false',
      'kendo.grid.filterBooleanAll': '(All)',
      'kendo.grid.filterAfterOrEqualOperator': 'Is after or equal to',
      'kendo.grid.filterAfterOperator': 'Is after',
      'kendo.grid.filterBeforeOperator': 'Is before',
      'kendo.grid.filterBeforeOrEqualOperator': 'Is before or equal to',
      'kendo.grid.filterFilterButton': 'Filter',
      'kendo.grid.filterClearButton': 'Clear',
      'kendo.grid.filterAndLogic': 'And',
      'kendo.grid.filterOrLogic': 'Or',
      'kendo.grid.filterDateToggle': 'Toggle calendar',
      'kendo.grid.filterDateToday': 'Today',
      'kendo.grid.filterNumericDecrement': 'Decrease value',
      'kendo.grid.filterNumericIncrement': 'Increase value',
      'kendo.grid.loading': 'Loading',
      'kendo.grid.sort': 'Sort',
      'kendo.grid.columnMenu': 'Column Menu',
      'kendo.grid.columns': 'Columns',
      'kendo.grid.lock': 'Lock',
      'kendo.grid.unlock': 'Unlock',
      'kendo.grid.sortAscending': 'Sort Ascending',
      'kendo.grid.sortDescending': 'Sort Descending',
      'kendo.grid.columnsApply': 'Apply',
      'kendo.grid.columnsReset': 'Reset',
      'kendo.grid.sortable': 'Sortable',
      'kendo.grid.sortedAscending': 'Sorted ascending',
      'kendo.grid.sortedDescending': 'Sorted descending',
      'kendo.grid.sortedDefault': 'Not sorted',
    },
  },
};

export class CustomMessageService extends MessageService {
  public set language(value: string) {
    const lang = data[value];
    if (lang) {
      this.localeId = value;
      this.notify(lang.rtl);
    }
  }

  public get language(): string {
    return this.localeId;
  }

  private localeId = 'es';
  private get messages(): any {
    const lang = data[this.localeId];

    if (lang) {
      return lang.messages;
    }
  }

  public get(key: string): string {
    return this.messages[key];
  }
}
