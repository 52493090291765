import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as serviceHelper from 'src/app/core/utils/service.helper';
import { environment } from 'src/environments/environment';
import { User } from '../../../shared/models/user';
import { VersionService } from '../version/version.service';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private static readonly resource: string = 'users';
  private resourceUrl: string;

  constructor(private http: HttpClient, private versionService: VersionService) {
    // this.resourceUrl = `${environment.apiURL}/${this.versionService.currentAPIVersion}/${UsersService.resource}`;
  }

  getResourceUrl() {
    return `${environment.apiURL}/${this.versionService.currentAPIVersion}/${UsersService.resource}`;
  }

  fetch(criteria?: any): Observable<any> {
    return this.http
      .get<any>(`${this.getResourceUrl()}`, { observe: 'response', params: serviceHelper.setParams(criteria) })
      .pipe(
        map((response) => {
          return {
            data: response.body.data,
            total: response.headers.get('Paginator-Total-Rows'),
          };
        }),
      );
  }

  get(id: string | number): Observable<any> {
    return this.http.get<any>(`${this.getResourceUrl()}/${id}`);
  }

  update(user: User, id: string | number): Observable<User> {
    return this.http.put<User>(`${this.getResourceUrl()}/${id}`, user);
  }

  delete(id: string | number): Observable<User> {
    return this.http.delete<User>(`${this.getResourceUrl()}/${id}`);
  }

  create(user: User): Observable<User> {
    return this.http.post<User>(`${this.getResourceUrl()}`, user);
  }
}
