import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment } from '@angular/router';
import { Customer } from 'src/app/shared/models/customer';
import { AuthService } from '../services/auth/auth.service';
import { CustomerService } from '../services/customer/customer.service';
import { UsersService } from '../services/users/users.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanLoad {
  constructor(
    private router: Router,
    private authService: AuthService,
    private customerService: CustomerService,
    private usersService: UsersService,
  ) {}

  // tslint:disable-next-line: cognitive-complexity
  async canLoad(route: Route, segments: UrlSegment[]): Promise<boolean> {
    if (this.authService.checkToken()) {
      if (!this.authService.loggedUser) {
        // Not logged
        const decodedToken = JSON.parse(atob(this.authService.getToken().split('.')[1]));

        const userResponse = await this.usersService
          .get(decodedToken.id)
          .toPromise()
          .catch(() => false);

        if (userResponse && userResponse.data) {
          // User OK
          this.authService.loggedUser = userResponse.data;

          if (this.authService.isRoleCMSAppUser()) {
            const customer = this.authService.loggedUser.customer as Customer;

            const customerResponse = await this.customerService
              .get(customer.id)
              .toPromise()
              .catch(() => false);

            if (customerResponse && customerResponse.data) {
              // User Customer OK
              const customer = customerResponse.data;
              this.authService.userCustomer = customer.parent_customer;
              return true;
            }
            // User KO
            this.router.navigate(['/login']);
            return false;
          }

          return true;
        }

        // User KO
        this.router.navigate(['/login']);
        return false;
      }

      // Token found and User already logged
      return true;
    }

    // Token NOT found
    this.router.navigate(['/login']);
    return false;
  }
}
