import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { Customer } from 'src/app/shared/models/customer';
import { User } from 'src/app/shared/models/user';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private static readonly resource: string = 'login_check';
  private readonly resourceUrl: string;

  private user: User;

  constructor(private http: HttpClient, private cookieService: CookieService) {
    this.resourceUrl = `${environment.apiURL}/${AuthService.resource}`;
  }

  get loggedUser() {
    return this.user;
  }

  set loggedUser(user: User) {
    this.user = user;
  }

  set userCustomer(customer: Customer) {
    this.user.customer = customer;
  }

  login(loginDTO: any): Observable<any> {
    const formData = new FormData();
    Object.keys(loginDTO).forEach((key) => formData.append(key, loginDTO[key]));

    return this.http.post<any>(this.resourceUrl, formData);
  }

  saveToken(token: string, expires?: number | Date) {
    this.cookieService.set('ls-token', token, expires, '/');
  }

  getToken(): string {
    return this.cookieService.get('ls-token');
  }

  checkToken(): boolean {
    return this.cookieService.check('ls-token');
  }

  deleteToken() {
    this.cookieService.delete('ls-token', '/');
  }

  checkAuthorities(rol: string) {
    return !!this.loggedUser.roles.find((userRol) => rol === userRol);
  }

  isAdmin() {
    return this.checkAuthorities('ROLE_ADMIN');
  }

  isRoleCMSAppUser() {
    return this.checkAuthorities('ROLE_CMS_APP_USER');
  }

  isRoleMobileAppUser() {
    return this.checkAuthorities('ROLE_MOBILE_APP_USER');
  }

}
