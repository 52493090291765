import { Component } from '@angular/core';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { CldrIntlService, IntlService } from '@progress/kendo-angular-intl';
import { MessageService } from '@progress/kendo-angular-l10n';
import { CustomMessageService } from './core/kendo/custom-message.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private intlService: IntlService,
    private translateService: TranslateService,
    private messageService: MessageService,
  ) {
    this.initializeTranslation();
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  private initializeTranslation() {
    this.translateService.addLangs(['en', 'es', 'it', 'pt', 'fr', 'ru']);
    this.translateService.setDefaultLang('en');

    let language = this.translateService.getBrowserLang();

    if (!this.translateService.getLangs().find((lang) => lang === language)) {
      language = this.translateService.getDefaultLang();
    }

    // this.messageService['language'] = language;

    this.translateService.use(language);

    (<CldrIntlService>this.intlService).localeId = language;

    const svc = <CustomMessageService>this.messageService;
    svc.language = language;
  }
}
