import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  private static readonly resource: string = 'version';
  private readonly resourceUrl: string;
  private version: string;

  constructor(
    private http: HttpClient,
  ) {
    this.resourceUrl = `${environment.apiURL}/${VersionService.resource}`;
  }

  get currentAPIVersion() {
    return this.version;
  }

  get(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}`).pipe(
      map(response => {
        if (response.data) {
          this.version = response.data.current_api_version;
        }
      })
    );
  }

  getAPIVersion(): Promise<any> {
    return this.http.get<any>(`${this.resourceUrl}`).pipe(
      // tslint:disable-next-line: no-identical-functions
      map(response => {
        if (response.data) {
          this.version = response.data.current_api_version;
        }
      }),
      catchError(() => EMPTY)
    ).toPromise();
  }



}
