import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { VersionService } from '../version/version.service';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  private static readonly resource: string = 'customers';

  constructor(private http: HttpClient, private versionService: VersionService) {}

  private getResourceUrl() {
    return `${environment.apiURL}/${this.versionService.currentAPIVersion}/${CustomerService.resource}`;
  }

  get(id: string | number): Observable<any> {
    return this.http.get<any>(`${this.getResourceUrl()}/${id}`);
  }
}
