import { HttpParams } from '@angular/common/http';
import * as moment from 'moment';

export function setParams(criteria?: any) {
  let params = new HttpParams();

  if (criteria) {
    Object.keys(criteria).forEach(key => {
      if (criteria[key] instanceof Array) {
        criteria[key].forEach((element: any) => {
          params = params.append(key, element);
        });
      } else if (criteria[key] instanceof Date) {
        params = params.set(key, moment(criteria[key]).format());
      } else {
        params = params.set(key, criteria[key]);
      }
    });
  }
  return params;
}
