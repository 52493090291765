import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  private readonly endpoint: string;

  toastButtons = [
    {
      text: 'Close',
      role: 'cancel'
    }
  ];

  constructor(
    private router: Router,
    private toastController: ToastController,
    private translateService: TranslateService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        // tslint:disable-next-line: no-empty
        if (event instanceof HttpResponse) { }
      }, async (err: any) => {

        if (err instanceof HttpErrorResponse) {

          let toast = {
            message: null,
            color: 'danger',
            duration: 8000,
            buttons: this.toastButtons
          }

          switch (err.status) {
            case 400:
              toast.message = `${this.translateService.instant('ERRORS.BAD_REQUEST')} (${err.status}): ${err.statusText}`;
              break;
            case 401:
              if (request && request.url !== `${environment.apiURL}/version`) {
                toast.message = `${this.translateService.instant('ERRORS.UNAUTHORIZED')} (${err.status}): ${err.statusText}`;
                this.router.navigateByUrl('/login');
              }
              break;
            case 404:
              //toast.message = `${this.translateService.instant('ERRORS.NOT_FOUND')} (${err.status}): ${err.statusText}`;
              break;
            case 412:
              toast.message = `${this.translateService.instant('ERRORS.PRECONDITION_FAILED')} (${err.status}): ${err.statusText}`;
              break;
            case 413:
              toast.message = this.translateService.instant('ERRORS.FILE_TOO_LARGE');
              break;
            case 500:
              toast.message = `${this.translateService.instant('ERRORS.SERVER_ERROR')} (${err.status}): ${err.statusText}`;
              break;
            case 503:
              toast.message = `${this.translateService.instant('ERRORS.SERVICE_NOT_AVAILABLE')} (${err.status}): ${err.statusText}`;
              break;
            default:
              //toast.message = `${this.translateService.instant('ERRORS.GENERIC_MESSAGE')} (${err.status}): ${err.statusText}`;
              break;
          }

          if (toast.message) {
            const toastView = await this.toastController.create(toast);
            toastView.present();
          }
        }
      })
    );
  }
}
