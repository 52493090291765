import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';
import { NG_VALIDATORS } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { IntlModule } from '@progress/kendo-angular-intl';
import '@progress/kendo-angular-intl/locales/en/all';
import '@progress/kendo-angular-intl/locales/es/all';
import '@progress/kendo-angular-intl/locales/it/all';
import '@progress/kendo-angular-intl/locales/pt/all';
import '@progress/kendo-angular-intl/locales/ru/all';
import { CookieService } from 'ngx-cookie-service';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { AppCodeISOValidateDirective } from '../shared/validators/codeISOValidate.directive';
import { EnsureModuleLoadedOnceGuard } from './ensureModuleLoadedOnceGuard';
import { ErrorHandlerInterceptor } from './interceptors/error-handler.interceptor';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { VersionService } from './services/version/version.service';

export function createTranslateLoader(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [{ prefix: './assets/i18n/', suffix: '.json' }]);
}

export function getAPIVersion(versionService: VersionService) {
  return () => versionService.getAPIVersion();
}

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    HttpClientModule,
    IntlModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [RouterModule, HttpClientModule, IntlModule, TranslateModule],
  providers: [
    CookieService,
    VersionService,
    {
      provide: APP_INITIALIZER,
      useFactory: getAPIVersion,
      deps: [VersionService], // dependancy
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: AppCodeISOValidateDirective,
      multi: true,
    },
  ],
})
export class CoreModule extends EnsureModuleLoadedOnceGuard {
  // Ensure that CoreModule is only loaded into AppModule
  // Looks for the module in the parent injector to see if it's already been loaded (only want it loaded once)
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    super(parentModule);
  }
}
