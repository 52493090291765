import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import iso6391 from 'iso-639-1';

export function validateISOCode(control: AbstractControl): { [key: string]: any } | null {
  if (control.value && !iso6391.validate(control.value)) {
    return { codeISOInvalid: true };
  }
  return null;
}

@Directive({
  selector: '[appCodeISOValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: AppCodeISOValidateDirective,
      multi: true,
    },
  ],
})
export class AppCodeISOValidateDirective implements Validator {
  validate(control: AbstractControl): { [key: string]: any } | null {
    return validateISOCode(control);
  }
}
